.data-visualization-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.button-panel {
  width: 20%;
  background-color: #f0f0f0;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.color-picker-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cluster-button {
  margin: 5px 0;
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.custom-color-button {
  margin: 5px 0;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  background-color: #e0e0e0;
}

.cluster-button:hover, .custom-color-button:hover {
  background-color: #d0d0d0;
}

.color-picker {
  margin-top: 5px;
  width: 100%;
}
