.semi-layout-header {
  background-color: #ffffff;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

body {
  padding-top: 60px; /* This should be equal to or greater than the height of your header */
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-icon {
  height: 50px; /* Set the height of the icon */
  margin-right: 10px;
}

.nav-link {
  padding: 0 10px;
  text-decoration: none;
  color: #000; /* Color for the navigation links */
  font-size: 0.7rem; /* Size of the navigation links */
}

.nav-link.active {
  font-weight: bold; /* Make the active link bold */
}

.support-email {
  font-size: 0.7rem; /* Size for the support email */
  text-decoration: none;
  color: #000; /* Color for the support email */
  margin-left: 20px; /* Spacing from the last navigation link */
}

/* Styles for ToolsPage */
.tools-page {
  text-align: center;
  padding: 20px;
}

.video-row {
  display: flex;
  justify-content: space-between; /* Space out the videos evenly */
  align-items: center;
  margin-bottom: 20px;
}

.tools-page iframe {
  max-width: 30%; /* Adjusted to fit three videos side by side */
  height: auto; /* Maintain aspect ratio */
  margin: 0 auto; /* Center the iframe horizontally */
  display: block; /* Make iframe a block element to allow for margin auto */
  border: 2px solid black;
  border-radius: 8px;
}

.tools-page p {
  max-width: 800px;
  margin: 20px auto; /* Center the text block horizontally and add some vertical space */
  padding: 1rem;
  text-align: justify;
}


/* Styles for JoinUsPage */
.join-us-page {
  max-width: 800px;
  margin: 0 auto; /* Center the content horizontally */
  padding: 20px;
  font-family: Arial, sans-serif;
}

.join-us-page h1 {
  text-align: center;
  margin-bottom: 30px;
}

.job-section {
  margin-bottom: 40px; /* Space between job sections */
}

.job-title {
  font-size: 1.2em; /* Larger font size for job title */
  font-weight: bold; /* Bold font weight for job title */
  margin-bottom: 10px; /* Space between the job title and location */
}

.job-location {
  font-style: italic; /* Italicize the location */
  margin-bottom: 20px; /* Space between the location and description */
}

.join-us-page p {
  margin-bottom: 10px;
  text-align: justify;
}

.apply-link {
  display: block; /* Display the link as block to make it stand on its own line */
  margin-top: 10px; /* Space above the link */
  margin-bottom: 20px; /* Space below the link */
  color: #0000EE; /* Standard link color */
  text-decoration: none; /* Remove underline */
}

.apply-link:hover {
  text-decoration: underline; /* Underline on hover */
}

/* HomePage styles */
.homepage {
  text-align: center; /* Center text and images within this container */
}

.center-image {
  width: 100vw;        /* Viewport width: ensures the image is always the full width of the viewport */
  height: auto;        /* Maintains the aspect ratio of the image */
  display: block;      /* Ensures the image is a block element and takes up the full width */
  margin: 0 auto;      /* Centers the image */
  max-height: calc(100vh - 60px); /* Optional: Limits the height, subtracting the height of the header */
}

.homepage-text {
  position: relative; /* Stack the text block in the natural flow */
  z-index: 20; /* Higher z-index to ensure visibility above the image */
  background: white; /* Optional: may help with readability over the image */
  max-width: 100%; /* Use 100% width for text to fit beneath the image */
  margin-top: -5%; /* Adjust as necessary to position the text block */
  padding: 1em;
}
.image-container {
  position: relative;
  text-align: center; /* Center the text inside this container */
}

.responsive-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.centered-text {
  position: absolute; /* Position the text absolutely inside its parent */
  top: 50%; /* Position at the top 50% of the parent */
  left: 50%; /* Position at the left 50% of the parent */
  transform: translate(-50%, -50%); /* Center the text exactly in the middle */
  color: white; /* Set text color */
  font-size: 2.5em; /* Set text size */
  font-weight: bold; /* Make the text bold */
  z-index: 10; /* Ensure the text is above the image */
}

.spatialomics-page {
  text-align: center;
}

.button-container {
  margin: 20px 0;
}

.button-container button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

#image-container {
  margin-top: 20px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}









/* Mobile styles */
@media (max-width: 768px) {
  .header-left, .header-right {
    flex-direction: row;
    align-items: space-between;
    width: 100%;
  }

  .header-right {
    justify-content: flex-end;
  }

  .header {
    position: fixed; /* Fixed or absolute depending on your design */
    top: 0;
    left: 0;
    right: 0;
    z-index: 100; /* High z-index to ensure it stays on top */
    background: #ffffff; /* Assuming a white background */
    /* Rest of your styling */
  }

  .header-icon {
    margin-right: 0; /* Remove margin for mobile layout */
    margin-bottom: 0.5em; /* Add some space below the icon */
    height: 40px; /* Adjust the size as needed for mobile */
  }

  .header .navigation {
    flex-direction: column;
    align-items: center; /* Center the navigation items on small screens */
  }

  .header .navigation-link {
    padding: 0.5rem; /* Adjust padding for mobile */
    display: block; /* Stack the links */
  }

  .header .support-email {
    order: 3; /* Push the email to the bottom of the stack */
    margin-top: 1rem; /* Add some space above the email */
  }

  .main-content {
    margin-top: 4rem; /* Adjust this value based on the actual height of your header */
  }

  .App-title {
    font-size: 1em; /* Adjust font size for mobile */
  }

  .support-email {
    font-size: 0.6rem; /* Adjust font size for mobile */
  }

  .semi-layout-header {
    flex-direction: column;
    align-items: flex-start; /* Align to the start for mobile */
  }

  .navigation-link {
    font-size: 0.8em; /* Adjust navigation link sizes */
  }

  .semi-layout {
    width: 100%; /* Use the full width for mobile */
    padding: 1rem; /* Adjust padding for mobile */
  }

  .image-container {
    margin-top: 60px; /* Push down the container by the header's height */
  }

  .center-image {
    width: 100vw; /* Viewport Width - ensures the image takes the full width */
    height: auto; /* Keep the image aspect ratio */
  }

  .centered-text {
    font-size: 4vw; /* Responsive font size based on viewport width */
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center align the text */
    width: 90%; /* Max width of text */
    text-align: center;
    padding-top: 1rem;
    z-index: 10;
  }

  .content {
    padding: 1rem; /* Adjust content padding */
  }

  .content p {
    font-size: 1em; /* Adjust paragraph text size */
  }

  .homepage .center-image {
    width: 100%; /* Full width images */
    height: auto; /* Maintain aspect ratio */
    position: relative;
    top: auto;
  }
  
  .join-us-page,
  .tools-page {
    padding: 1rem;
  }

  .job-section .job-title {
    font-size: 1em; /* Adjust job title size for mobile */
  }

  .apply-link {
    font-size: 0.8em; /* Adjust link size for mobile */
  }

  /* Adjust the YouTube iframe size for mobile */
  .tools-page iframe {
    width: 100%; /* Full width on mobile */
    height: auto; /* Maintain aspect ratio */
  }

}

.semi-layout {
  margin-top: 60px; /* Add a top margin that is at least the height of the header */
}